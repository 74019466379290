import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdminService } from 'src/app/admin.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { FiltersComponent } from '../filters/filters.component';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-purchase-bookings',
  templateUrl: './purchase-bookings.component.html',
  styleUrls: ['./purchase-bookings.component.css']
})
export class PurchaseBookingsComponent implements OnInit {
  isDetails: boolean = false;
  searchByNameForm: FormGroup;
  jobObj: any;
  taskStatus: string = "All";
  pageNo: number = 1;
  filterForm: FormGroup;
  baseUrl: string = "";
  jobs: Array<any> = [];
  isSearchByName: boolean = false;
  category: Array<any> = [];
  filteredData: Array<any> = [];
  totalPageCount: number = 0;
  pageNumbers: Array<any> = [];
  apiStatus: string = "Fetching Bookings";
  categoryList: Array<any> = [];
  categoryRes: any;
  dataMessage: string = "Fetching Data..";
  buyingID: string = "";
  userID: string = "";
  jobsCopy: Array<any> = [];
  constructor(
    private fb: FormBuilder,
    private adminService: AdminService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.jobObj = {
      "type": this.taskStatus,
      "pageNo": "" + this.pageNo,
      "size": "20"
    };
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      pageNo: [""],
      size: ["21"]
    })
    this.filterForm = this.fb.group({
      dateRange: [null]
    })
    //this.adminService.showLoader.next(true);
    this.baseUrl = this.adminService.baseUrl;
    this.getJobs();
    this.browseCategory();
  }

  previousPage() {
    this.pageNo -= 1;
    if (this.isSearchByName) {
      this.getBookingsByName();
    } else {
      this.getJobs()
    }
  }
  nextPage() {
    this.pageNo += 1;
    if (this.isSearchByName) {
      this.getBookingsByName();
    } else {
      this.getJobs()
    }
  }

  searchBookingByName() {
    if (this.searchByNameForm.valid) {
      this.jobs = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getBookingsByName()
    } else {
      this.getJobs();
      //this.openSnackBar("Enter text..", "")
    }
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  gotoSelectedPage(number) {
    this.pageNo = number;
    if (this.isSearchByName) {
      this.getBookingsByName();
    } else {
      this.getJobs()
    }
  }

  openDetails(jobs) {
    // console.log(jobs);
    this.isDetails = true;
    // console.log(jobs.buyingID);
    this.buyingID = jobs.buyingID;
    this.userID = jobs.buyingID;
  }
  receiveMessage(event) {
    this.isDetails = !event
  }
  getBookingsByName() {
    this.pageNumbers = [];
    this.dataMessage = "Fetching Data...";
    let token = sessionStorage.getItem('token');
    this.searchByNameForm.patchValue({
      pageNo: "" + this.pageNo
    })
    this.adminService.showLoader.next(true);
    this.adminService.getBuyProjectBySearch(this.searchByNameForm.value, token).subscribe((posRes) => {
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        if (posRes.FetchBuyandsellProjects.length > 0) {
            this.jobs = posRes.FetchBuyandsellProjects;       
           this.jobsCopy = posRes.FetchBuyandsellProjects;
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }         
          this.filteredData = this.jobs
        }
        else {
          this.dataMessage = "No Bookings Found...";
          this.filteredData = [];
          this.totalPageCount = 1;
          //this.openSnackBar(posRes.message, "");
        }
      } 
      
      else {
        this.dataMessage = "No Bookings Found...";
        this.filteredData = [];
        this.totalPageCount = 1;
        //this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.dataMessage = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isSearchByName = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }


  // Browse Category
  browseCategory() {
    this.adminService.browseCategory().subscribe((posRes) => {
      if (posRes.response == 3) {
        // console.log("Browselist", posRes.categoriesList);
        this.category = posRes.categoriesList;
        this.categoryList = posRes.categoriesList;
        sessionStorage.setItem('Category', JSON.stringify(this.category));
        sessionStorage.setItem('CategoryInfo', JSON.stringify(posRes));
      } else {
        this.openSnackBar(posRes.message, "")
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.warn("CSE", err.message);
      } else {
        console.warn("SSE", err.message);

      }
    })
  }
  taskFilter(type) {
    this.taskStatus = type;
    this.jobObj.type = type;
    this.getJobs();
    // this.browseCategory();
    // this.getFilters()
  }
  getJobs() {
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      pageNo: [""],
      size: ["21"]
    })
    this.pageNumbers = [];
    this.isSearchByName = false;
    this.adminService.showLoader.next(true);
    this.dataMessage = "Fetching Data..."
    this.jobObj.pageNo = "" + this.pageNo;
    let token = sessionStorage.getItem('token');
    // console.log(this.jobObj);
    this.adminService.fetchAllProjectbookings(this.jobObj, token).subscribe((posRes: any) => {
      this.dataMessage = "No Data Found"
      this.adminService.showLoader.next(false);
      // console.log("results", posRes);
      if (posRes.response == 3) {

        this.jobs = posRes.BuySellProjectsList;
        if (this.jobs.length > 0) {
          this.jobsCopy = posRes.BuySellProjectsList;
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          sessionStorage.setItem('allPurchaseProjects', JSON.stringify(this.jobs))
          this.filteredData = this.jobs;
        }
      } else {
        this.filteredData = [];
        this.totalPageCount = 1;
        this.openSnackBar(posRes.message, "");
      }

    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.error, "");
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }

  bookingFilters() {
    let obj = {
      from: "AllBookings",
      category: this.category,
      filters: this.jobObj
    }
    this.pageNo = 1
    let dialogRef = this.dialog.open(FiltersComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.pageNo) {
        this.filteredData = [];
        this.jobObj = res;
        this.getJobs()
      }
    })
  }
}
