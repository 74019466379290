import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { AdminService } from 'src/app/admin.service';
import { HttpErrorResponse } from '@angular/common/http';
import { FiltersComponent } from '../filters/filters.component';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-purchase-projects',
  templateUrl: './purchase-projects.component.html',
  styleUrls: ['./purchase-projects.component.css']
})
export class PurchaseProjectsComponent implements OnInit {
  isDetails: boolean = false;
  searchByNameForm: FormGroup;
  jobs: Array<any> = [];
  pageNo: number = 1;
  pageNumbers: Array<any> = [];
  isSearchByName: boolean = false;
  dataMessage: string = "Fetching Data..";
  jobsCopy: Array<any> = [];
  totalPageCount: number = 0;
  filteredData: Array<any> = [];
  jobObj: any;
  categoryList: Array<any> = [];
  taskStatus: string = "All";
  category: any;
  baseUrl: string = "";
  projectID: string = "";
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private adminService: AdminService,
    private dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit() {
    this.categoryList = [
      {
        "categoryId": "Cat8zP3wooW",
        "categoryName": "iOS",
        "image": "/images/Category/xLGPu1638505177697moving@2x.png",
        "categoryDetailsLink": "/Categories/IOSDevelopmentService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/IOSDevelopmentService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatzhfczDKk",
        "categoryName": "Cloud",
        "image": "/images/Category/iBnHX1661257535190_Name_cloud.png",
        "categoryDetailsLink": "/Categories/CloudDevelopmentService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/CloudDevelopmentService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatQ0qw7IPl",
        "categoryName": "UI/UX",
        "image": "/images/Category/hDIAC1638505252208handyman@2x.png",
        "categoryDetailsLink": "/Categories/UIUXDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/UIUXDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat8ihwg6xt",
        "categoryName": "Circuit/Schematic Design",
        "image": "/images/Category/IKfrO1638505379546admin@2x.png",
        "categoryDetailsLink": "/Categories/CircuitSchematicDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/CircuitSchematicDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat9d6a6RN4",
        "categoryName": "PCB Layout",
        "image": "/images/Category/BMTyw1638505406966dsigner@2x.png",
        "categoryDetailsLink": "/Categories/PCBLayoutDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/PCBLayoutDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat31Wi6BMw",
        "categoryName": "Firmware",
        "image": "/images/Category/VVySE1638505443989waiter@2x.png",
        "categoryDetailsLink": "/Categories/FirmwareService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/FirmwareService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatcKpQDv1i",
        "categoryName": "Industrial Design",
        "image": "/images/Category/UbLlc1638505476718industreal_design@2x.png",
        "categoryDetailsLink": "/Categories/IndustrialDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/IndustrialDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatWxZVkG2b",
        "categoryName": "Fabrication",
        "image": "/images/Category/FEvvc1638505507380fabrication@2x.png",
        "categoryDetailsLink": "/Categories/Fabrication.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/Fabrication.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CattUg0eXTS",
        "categoryName": "Assembly",
        "image": "/images/Category/VEJua1638505534252assembly@2x.png",
        "categoryDetailsLink": "/Categories/AssemblyService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/AssemblyService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatIXvPGq4k",
        "categoryName": "Marketing",
        "image": "/images/Category/gAxhg1638505590351internet _Marketing@3x.png",
        "categoryDetailsLink": "/Categories/MarketingService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/MarketingService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatwQrHcvEA",
        "categoryName": "SEO",
        "image": "/images/Category/gUmaF1638505622945seo@3x.png",
        "categoryDetailsLink": "/Categories/SEOService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/SEOService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatdVqLcjRL",
        "categoryName": "Amazon Web Services",
        "image": "/images/Category/yVgKb1638505681184aws@3x.png",
        "categoryDetailsLink": "/Categories/AWS.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/AWS.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat2sP7iHXd",
        "categoryName": "Content Writing",
        "image": "/images/Category/tAGeI1638505712376content_writing@3x.png",
        "categoryDetailsLink": "/Categories/ContentWritingService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/ContentWritingService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat537PeVUS",
        "categoryName": "Data Entry",
        "image": "/images/Category/ZiccE1638505742797data_entry@3x.png",
        "categoryDetailsLink": "/Categories/DataEntryService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/DataEntryService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatznEgAafB",
        "categoryName": "Website Development",
        "image": "/images/Category/UxALh1652163464858_Name_website_development@2x.png",
        "categoryDetailsLink": "/Categories/WebsiteDevelopmentService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/WebsiteDevelopmentService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatjcHJKkDC",
        "categoryName": "Logo Design",
        "image": "/images/Category/uxuvH1652163493170_Name_logo_design@2x.png",
        "categoryDetailsLink": "/Categories/LogoDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/LogoDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Catl7A0QIjI",
        "categoryName": "Mobile Application",
        "image": "/images/Category/VlIon1652163517376_Name_mobile_application@2x.png",
        "categoryDetailsLink": "/Categories/MobileApplicationService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/MobileApplicationService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatXcFxClC6",
        "categoryName": "Digital Marketing",
        "image": "/images/Category/ozqkC1652163542093_Name_digital_marketing@2x.png",
        "categoryDetailsLink": "/Categories/DataEntryService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/DigitalMarketingService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatU9uDUfKM",
        "categoryName": "Graphic Design",
        "image": "/images/Category/GuEQZ1652163567639_Name_graphic_design@2x.png",
        "categoryDetailsLink": "/Categories/GraphicDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/GraphicDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CataWHaFsSC",
        "categoryName": "Medical Billing & Coding",
        "image": "/images/Category/UMubn1652163596400_Name_medical_billing_coding@2x.png",
        "categoryDetailsLink": "/Categories/MedicalBilling&CodingService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/MedicalBilling&CodingService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatOZyxLqZB",
        "categoryName": "Mother Board Layout",
        "image": "/images/Category/LSasm1652164263529_Name_motherboard_layout@2x.png",
        "categoryDetailsLink": "/Categories/MotherBoardLayoutService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/MotherBoardLayoutService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatQH0rcDTC",
        "categoryName": "Resume Making",
        "image": "/images/Category/gRlFK1652163683917_Name_ressume_making@2x.png",
        "categoryDetailsLink": "/Categories/ResumeMakingService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/ResumeMakingService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatTI3gcJkr",
        "categoryName": "Voice Over",
        "image": "/images/Category/AhfVM1652163712966_Name_voice_over@2x.png",
        "categoryDetailsLink": "/Categories/VoiceOverService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/VoiceOverService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatvAKQHVfs",
        "categoryName": "Illustration",
        "image": "/images/Category/mgwHW1652163734258_Name_illustration@2x.png",
        "categoryDetailsLink": "/Categories/IllustrationService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/IllustrationService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat2LNnD2ex",
        "categoryName": "Word Press Making",
        "image": "/images/Category/zQwAT1652163764383_Name_wordpress_making@2x.png",
        "categoryDetailsLink": "/Categories/WordPressMakingService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/WordPressMakingService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatXEO1g6lU",
        "categoryName": "Ecommerce Consultant",
        "image": "/images/Category/pkmFu1652163785858_Name_ecommerce_consultant@2x.png",
        "categoryDetailsLink": "/Categories/Ecommerce.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/Ecommerce.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatQJrWVGmt",
        "categoryName": "ATE Boards",
        "image": "/images/Category/mrVCw1652163861696_Name_ate_boards@2x.png",
        "categoryDetailsLink": "/Categories/ATEBoardsService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/ATEBoardsService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatGnoaTL8C",
        "categoryName": "Probe Card Layout",
        "image": "/images/Category/quhLd1652163887196_Name_probecard_layout@2x.png",
        "categoryDetailsLink": "/Categories/ProbeCardLayoutService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/ProbeCardLayoutService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "Cat6oOiFm6s",
        "categoryName": "Burning Board Layout",
        "image": "/images/Category/uznUH1652163908842_Name_burning_boards@2x.png",
        "categoryDetailsLink": "/Categories/BurningBoardLayoutService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/BurningBoardLayoutService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatQ9vB8ev0",
        "categoryName": "Load Board Layout",
        "image": "/images/Category/FCfTE1652163934028_Name_loadboard_layouts@2x.png",
        "categoryDetailsLink": "/Categories/LoadBoardLayoutService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/LoadBoardLayoutService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatFN268DNF",
        "categoryName": "MLC/MLO Substrate Design",
        "image": "/images/Category/zQVRn1652163954161_Name_mlc_mlo_susrate_design@2x.png",
        "categoryDetailsLink": "/Categories/MLCMLOSubstrateDesignService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/MLCMLOSubstrateDesignService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatoOYeUNzd",
        "categoryName": "OS Board Layout",
        "image": "/images/Category/WthYF1652163972470_Name_os_board_layout@2x.png",
        "categoryDetailsLink": "/Categories/OSBoardLayoutService.html",
        "CategoryDownload_URL": "/CategoriesPDFGenerate/OSBoardLayoutService.pdf",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      },
      {
        "categoryId": "CatwTWCeQGQ",
        "categoryName": "Others",
        "image": "/images/Category/MioOQ1638505765817others@3x.png",
        "categoryDetailsLink": "",
        "CategoryDownload_URL": "",
        "HowItWorksLink": "/HowItworksSoftexer.pdf"
      }
    ]
    this.jobObj = {
      type: "All",
      pageNo: "" + this.pageNo,
      size: "10"
    }
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      pageNo: [""],
      size: ["10"]
    })
    this.getJobs();
    this.browseCategory();
    // this.category = JSON.parse(sessionStorage.getItem("CategoryInfo"));
    // this.categoryList = this.category.categoriesList;
    // console.log(this.categoryList);
    this.baseUrl = this.adminService.baseUrl;
    // this.categoryList = this.adminService.categoriesList;
    // if(this.categoryList.length == 0){
    //   this.browseCategory();
    // }
  }
  searchTaskByName() {
    if (this.searchByNameForm.valid) {
      this.jobs = []
      this.pageNo = 1;
      this.isSearchByName = true;
      this.getTasksByName()
    } else {
      this.getJobs();
      //this.openSnackBar("Enter text..", "")
    }
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }

  getTasksByName() {
    this.pageNumbers = [];
    this.dataMessage = "Fetching Data...";
    let token = sessionStorage.getItem('token');
    this.searchByNameForm.patchValue({
      pageNo: "" + this.pageNo
    })
    this.adminService.showLoader.next(true);
    // console.log(this.searchByNameForm.value);
    this.adminService.getProjectBySearch(this.searchByNameForm.value, token).subscribe((posRes) => {
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        if (posRes.FetchProjects.length > 0) {
          this.jobs = posRes.FetchProjects;
          this.jobsCopy = posRes.FetchProjects;
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          // this.jobs.forEach((val, i) => {
          //   // this.jobs.taskDate = new Date(parseFloat(val.taskDate));
          //   this.jobs[i].postedDate = new Date(val.postedDate * 1);
          //   if (val.budget.budgetType.Total == false) {
          //     let num: number = parseInt(val.budget.Hours);
          //     this.jobs[i].budget.budget = num * val.budget.pricePerHour;
          //   }
          // })
          this.filteredData = this.jobs
        }
        else{
          this.dataMessage = "No projects Found...";
          this.filteredData = [];
           this.totalPageCount = 1;
          //this.openSnackBar(posRes.message, "");
        }
      } else {
        this.dataMessage = "No projects Found...";
        this.filteredData = [];
        this.totalPageCount = 1;

       // this.openSnackBar(posRes.message, "");
      }
    }, (err: HttpErrorResponse) => {
      this.dataMessage = "No Users Found.."
      this.openSnackBar(err.message, "")
      this.pageNo = this.pageNo - 1;
      this.isSearchByName = false;
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("Client SIde Error", err.error);
      } else {
        console.warn("Server Error", err.error);
      }
    })

  }
  taskFilters() {
    // console.log("BBBBB");
    let obj = {
      from: "AllTasks",
      filters: this.jobObj,
      category: this.categoryList
    }
    this.pageNo = 1
    let dialogRef = this.dialog.open(FiltersComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: true,
      data: obj
    })
    dialogRef.afterClosed().subscribe(res => {
      if (res && res.pageNo) {
        this.filteredData = [];
        this.jobObj = res;
        this.getJobs()
      }
    })
  }
  previousPage() {
    this.pageNo -= 1;
    if (this.isSearchByName) {
      this.getTasksByName();
    } else {
      this.getJobs();
    }
  }
  nextPage() {
    this.pageNo += 1;
    if (this.isSearchByName) {
      this.getTasksByName();
    } else {
      this.getJobs();
    }
  }
  gotoSelectedPage(number) {
    this.pageNo = number;
    if (this.isSearchByName) {
      this.getTasksByName();
    } else {
      this.getJobs()
    }
  }
  taskFilter(type) {
    // console.log("AAA", type);
    this.taskStatus = type;
    this.jobObj.type = type;
    this.getJobs();
    // this.browseCategory();
    // this.getFilters()
  }

  // Browse Category
  browseCategory() {
    this.adminService.browseCategory().subscribe((posRes) => {
      // console.log(posRes);
      if (posRes.response == 3) {
        //this.categoryList = posRes.categoriesList;
        // console.log(this.categoryList);
      } else {
        this.openSnackBar(posRes.message, "")
      }
    }, (err: HttpErrorResponse) => {
      if (err.error instanceof Error) {
        console.warn("CSE", err.message);
      } else {
        console.warn("SSE", err.message);

      }
    })
  }

  getJobs() {
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],
      pageNo: [""],
      size: ["10"]
    })
    this.pageNumbers = [];
    this.isSearchByName = false;
    this.adminService.showLoader.next(true);
    this.dataMessage = "Fetching Data..."
    this.jobObj.pageNo = "" + this.pageNo;
    let token = sessionStorage.getItem('token');
    // console.log(this.jobObj);
    this.adminService.getFilteredPurchaseProjects(this.jobObj, token).subscribe((posRes: any) => {
     // this.dataMessage = "No Data Found"
      this.adminService.showLoader.next(false);
      // console.log("results", posRes);
      if (posRes.response == 3) {
        this.jobs = posRes.purchaseProjectsList;
        if (this.jobs.length > 0) {
          this.jobsCopy = posRes.purchaseProjectsList;
          this.totalPageCount = posRes.pages;
          for (let i: number = 0; i < this.totalPageCount; i++) {
            this.pageNumbers.push(i + 1);
          }
          sessionStorage.setItem('allPurchaseProjects', JSON.stringify(this.jobs))
          // this.jobs.forEach((val, i) => {
          //    this.jobs.taskDate = new Date(parseFloat(val.taskDate));
          //   this.jobs[i].postedDate = new Date(val.postedDate * 1);
          //   if (val.budget.budgetType.Total == false) {
          //     let num: number = parseInt(val.budget.Hours);
          //     this.jobs[i].budget.budget = num * val.budget.pricePerHour;
          //   }
          // })
          this.filteredData = this.jobs;
        } else {
          this.filteredData = [];
          this.totalPageCount = 1;
          this.dataMessage = "No projects Found...";
          //this.openSnackBar(posRes.message, "");
        }

        // this.viewPort.scrollToIndex(num -20,'smooth')
      } else {
        this.filteredData = [];
        this.totalPageCount = 1;
        this.dataMessage = "No projects Found...";
       // this.openSnackBar(posRes.message, "");
      }

    }, (err: HttpErrorResponse) => {
      this.openSnackBar(err.error, "");
      this.adminService.showLoader.next(false);
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }
  receiveMessage(event) {
    this.isDetails = !event
  }
  openDetails(job) {
    this.isDetails = true;
    // console.log(job.projectID);
    this.projectID = job.projectID;
    // this.router.navigate(['admin','details',job.postID])
  }
  addProject() {
    this.router.navigateByUrl("/admin/addprojects");
  }

}
