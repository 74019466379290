import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from 'src/app/admin.service';
import { NgbDate, NgbCalendar, NgbDateParserFormatter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { FiltersComponent } from '../filters/filters.component';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { Label, Color } from 'ng2-charts';
import { MatMenuModule, MatButtonModule } from '@angular/material';
import { FormControl } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FormsModule, } from '@angular/forms';
import * as internal from 'assert';
import { MatNativeDateModule } from '@angular/material/core';
import { parse } from 'querystring';
import { ImgPreviewComponent } from '../img-preview/img-preview.component';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.css']
})
export class PaymentsComponent implements OnInit {
  PaymentForm: FormGroup; 
  isDetails: boolean = false;
  // searchText: any;
  ShowGraph: boolean = true;
  showOptions: boolean = false;
  showOptions_online: boolean = false;
  searchByNameForm: FormGroup;
  taskStatus: string = "All";
  maxDate: any = new Date();
  maxDate1: any = new Date();
  paymentDetails: Array<any> = [];
  ManualData: Array<any> = [];
  paymentDetails_online: Array<any> = [];
  attachmentlistArray:Array<any> = [];
  isSearchByName: boolean = false;
  DateFilter: any = [];
  attachmets:Array<any> = [];
  DateFilter1: any = [];
  isSelected: boolean = false;
  showDetails: boolean = false;
  message:string = "Finding PaymentData.."
  image: any;
  baseurl: string = "";
  type: string = "All";
  onlinetype: string = "All";
  paymentInfo: any;
  paymentInfo_online: any;
  FilterChk: boolean = false;
  FilterChk_online: boolean = false;
  ButtnChk: boolean = true;
  FilterDate: Array<any> = [];
  calfilterType: string = "thisYear";
  totalAmount_Manual: string = "0.00";  
  project_ManualAmt: number = 0;  
  profile_ManualAmt: number = 0; 
  top10_ManualAmt: number = 0; 
  buysell_ManualAmt: number = 0; 
  advertise_ManualAmt: number = 0; 
  userpay_ManualAmt: number = 0;
  paymentStatus: Array<any> = ["Approve", "Rejected", "Approved"];
  paymentStatus_online: Array<any> = ["Approve", "Rejected", "Approved"];
  paymentSt: string = "All";
  paymentSt_online: string = "All";
  isHourly: any;
  Isclicked: boolean = false;
  Isclicked_online: boolean = false;
  count: any;
  filtertypeAmount: any;
  userID: any;
  div1: boolean = true;
  div1_online: boolean = true;
  isBookingDateRange: boolean = false;
  isBookingDateRange1: boolean = false;
  label: any;
  chkstatus: boolean = false;
  chkstatus_online: boolean = false;
  p1: boolean = false;
  type1: any;
  Onlinetype1: any;
  bookingData: any;
  bookingsForm: FormGroup;
  bookingsForm1: FormGroup;
  PaymentTable: Array<any> = [];
  finalData: Array<any> = [];
  kk: Array<any> = [];
  selectedstatus: any = "All";
  selectedstatus_online: any = "All";
  onlineID: number = 0;
  status: any = {
    getTypeFilter: "thisYear"
  };
  totalonlinepaymnet: number = 0;
  totalonlinepaymnet_projectpay: number = 0;
  totalonlinepaymnet_profilepay: number = 0;
  totalonlinepaymnet_top10pay: number = 0;
  totalonlinepaymnet_buysellpay: number = 0;
  totalonlinepaymnet_advertisepay: number = 0;
  totalonlinepaymnet_hireuser: number = 0;
  isOnline: boolean = false;
  isUserDetails: boolean = false;
  style1: boolean = false;
  style2: boolean = false;
  quickResult: Array<any> = [];
  public lineChartLabels: Label[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public lineChartData: ChartDataSets[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          ticks: {
            display: true,
          },
          gridLines: {
            color: "rgba(0, 0, 0,0)"
          }
        }
      ],
      yAxes: [
        {
          ticks: {
            display: true,
            beginAtZero: true,
            stepSize: 1
          },
          gridLines: {
            color: "rgba(0, 0, 0,0)"
          }
        }
      ]
    },
    elements:
    {
      point:
      {
        radius: 8,
        hitRadius: 40,
        hoverRadius: 10,
        hoverBorderWidth: 5
      }
    },
    legend: {
      position: 'top',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    }
  };
  public lineChartColors: Color[] = [
    {
      borderColor: 'skyblue',
      backgroundColor: 'rgba(255,255,255,0.3)',
    },
  ];
  public lineChartLegend = false;
  public lineChartType = 'line';
  public lineChartPlugins = [];
  // Bar Charts Code
  chartOptions = {
    responsive: true,
    legend: {
      position: 'top',
      labels: {
        fontSize: 10,
        usePointStyle: true
      }
    },
    scales: {
      xAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }],
      yAxes: [{
        gridLines: {
          color: "rgba(0, 0, 0, 0)",
        }
      }]
    }
  };

  @ViewChild('t', { static: false }) datePicker: NgbInputDatepicker;
  @ViewChild("scroll", { static: false }) scrollContainer: ElementRef;
  @ViewChild(CdkVirtualScrollViewport, { static: false }) viewPort: CdkVirtualScrollViewport;
  hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;

  constructor(
    private adminService: AdminService,
    private router: Router,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private dialog:MatDialog, 
  ) { }

  ngOnInit() {
    this.bookingsForm = this.fb.group({
      dateRange: ["", Validators.required]
    })
    this.bookingsForm1 = this.fb.group({
      dateRange1: ["", Validators.required]
    })
    this.searchByNameForm = this.fb.group({
      searchText: ["", Validators.required],    
    })
    this.baseurl = "https://api.softexer.com"   
    // console.log("first");
    this.ProjectPaymts(this.type);
    this.totalPaymentInfo(this.calfilterType);
    // console.log("showooptionsttaus",this.showOptions);
  }
  clearInputMethod2() {
    this.bookingsForm1.setValue({ dateRange1: "" });
    this.bookingsForm.setValue({ dateRange: "" });
    // this.searchText = "";
    this.showOptions = false;
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  //  Payment method wise  fetching
  changeView(event) {
    // console.log("a", event.value);
    if (event.value == "online") {
      this.isOnline = true;
      this.onlinetype = "All";
      this.selectedstatus_online = "All";     
      this.onlineID = 6;
      this.ProjectPaymts_online(this.onlinetype);
      // console.log("onlinestatus", this.onlinetype);
    } else {
      this.isOnline = false;
      this.showOptions = false;
      this.type= "All";
      this.selectedstatus = "All";
      this.ProjectPaymts(this.type);
      this.totalPaymentInfo(this.calfilterType);
    }
  }
  totalPaymentInfo(calfilterType) {
    this.showOptions = false;
    let TablePayload = {
      filterType: calfilterType
    }
    this.adminService.PaymentTableData(TablePayload).subscribe((res) => {
      if (res.response == 3) {
        this.PaymentTable = res.info;
        this.count = res.count;
        this.filtertypeAmount = res.filterTypeAmount;
        this.totalAmount_Manual = res.AllTotalManualPayments;
      }
      else {
        this.PaymentTable = res.info;
        this.PaymentTable = [];
        this.count = res.count;
        this.filtertypeAmount = res.filterTypeAmount;
        this.totalAmount_Manual = res.AllTotalManualPayments;
        //this.openSnackBar(res.message, "")
      }
    })
  }
  getBookingByRange1() {
    this.bookingsForm.setValue({dateRange: "" });    
    this.searchByNameForm.setValue({searchText: ""})
      
   // this.searchText = "";
    this.showOptions = false;
    this.isBookingDateRange1 = true;
    // if (this.searchText != "") {
    //   this.searchText = "";
    // }
    let frmDate = new Date(this.bookingsForm1.value.dateRange1[0]).getTime();
    let toDate = new Date(this.bookingsForm1.value.dateRange1[1]).getTime();
    frmDate = new Date(this.bookingsForm1.value.dateRange1[0]).setHours(0, 0, 0, 0);
    toDate = new Date(this.bookingsForm1.value.dateRange1[1]).setHours(23, 59, 59, 999);
    this.DateFilter1.push(frmDate);
    this.DateFilter1.push(toDate);
    this.getdatefilterdata();
  }

  getdatefilterdata() {
    this.showOptions = false;
    let DatePayload = {
      filter: this.DateFilter1
    }
    // console.log("DatePayload", DatePayload);
    this.adminService.DateRangePayments(DatePayload).subscribe((res) => {
      if (res.response == 3) {
        // console.log(res.filterInfo);
        this.paymentDetails = res.filterInfo;
        // console.log("date successfully");
      }
      else {
        this.paymentDetails = [];
        //this.openSnackBar(res.message, "")
      }
      this.DateFilter1 = [];
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);
      //this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }
  searchBookingByName() {
   // this.isFilterdata = true;
    this.Isclicked = false;
    this.div1 = false;
    if (this.searchByNameForm.valid) {     
      this.isSearchByName = true;
      this.getBookingsByName()
    } else {     
      this.isSearchByName = false;
      this.ProjectPaymts(this.type);
      //this.openSnackBar("Enter text..", "")
    }
  }

  getBookingsByName() {
    this.Isclicked = false;
    this.div1 = false;
    let token = sessionStorage.getItem('token');
    this.div1
  
    let SearchPayload;
    let Stxt=this.searchByNameForm.get("searchText").value;
    // console.log("seartxt", Stxt);
    if(Stxt == ""){

    }else{
      // SearchPayload["Search"] =Stxt;
      SearchPayload ={
        Search : Stxt

      }
    }
    
    // console.log(this.searchByNameForm.value);
    this.adminService.showLoader.next(true);
    this.adminService.SearchManualPayment(SearchPayload).subscribe((posRes) => {
      // console.log(posRes);
      this.adminService.showLoader.next(false);
      if (posRes.response == 3) {
        this.paymentDetails = posRes.Info;
        if(this.paymentDetails.length>0){
          
          this.paymentDetails = posRes.Info;
        }
        
      } else {
        this.paymentDetails = [];
        this.message = "No Data Found.."
       
      }
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);    
      this.ProjectPaymts(this.type);
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })  

  }
  applyFilter(searchText) {
    // this.showOptions = false;
    // this.clearInputMethod2();
    // this.searchText = searchText;
    // // console.log(this.searchText);
    // // if (this.searchText.length >= 2) {
    //   let SearchPayload = {
    //     Search: this.searchText
    //   }
    //   this.adminService.SearchManualPayment(SearchPayload).subscribe((res) => {
    //     if (res.response == 3) {          
    //       this.paymentDetails = res.Info;
    //       // console.log(res.Info);
    //     }
    //     else {
    //       this.paymentDetails = [];
    //       // this.openSnackBar("No Data Found", "");
    //       this.message = "No Data Found.."
    //     }
    //   }, (err: HttpErrorResponse) => {
    //     this.adminService.showLoader.next(false);
    //     // this.openSnackBar(err.message, "");
    //    // this.message = "Enter Text.."
    //    this.ProjectPaymts(this.type);
    //     if (err.error instanceof Error) {
    //       console.warn("CSError", err.error)
    //     } else {
    //       console.warn("SSError", err.error)
    //     }
    //   })  
  
  }

  ProjectPaymts(name) {
    this.showOptions = false;
    this.Isclicked = false;
    this.type = name;
    let p1Payload = {
      type: name
    }
    this.type = name;
    // console.log(this.type);    
    this.message = "No PaymentData Found.."
    this.project_ManualAmt =0.00;  
    this.profile_ManualAmt=0.00;  
    this.top10_ManualAmt= 0.00;  
    this.buysell_ManualAmt =0.00;  
    this.advertise_ManualAmt = 0.00;  
    this.userpay_ManualAmt = 0.00;
    this.ManualData = [];
    this.searchByNameForm.patchValue({
      searchText : ""
    })
    this.adminService.getPayments(p1Payload).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails = res.StatusPaymentInfo;
        // console.log("result",res.StatusPaymentInfo);    

        if (name == "ProjectPayments") {          
          let bookingsData = res.StatusPaymentInfo;
          bookingsData.filter(word => {
          let dataobj = {}         
          dataobj["Amount"] = parseInt(word.Amount);       
          this.ManualData.push(dataobj);         
           this.project_ManualAmt +=parseInt(word.Amount);    
          // console.log("pay",this.project_ManualAmt);
        })
        }
        if (name == "ProfileViewPayments") {
          let bookingsData = res.StatusPaymentInfo;
          bookingsData.filter(word => {
          let dataobj = {}         
          dataobj["Amount"] = parseInt(word.Amount);       
          this.ManualData.push(dataobj);         
           this.profile_ManualAmt +=parseInt(word.Amount);             
        })
        }
        if (name == "ProjectTopTenListsPayments") {
          let bookingsData = res.StatusPaymentInfo;
          bookingsData.filter(word => {
          let dataobj = {}         
          dataobj["Amount"] = parseInt(word.Amount);       
          this.ManualData.push(dataobj);         
           this.top10_ManualAmt +=parseInt(word.Amount);             
        })
        }
        if (name == "BuySellProjectsPayments") {
          let bookingsData = res.StatusPaymentInfo;
          bookingsData.filter(word => {
          let dataobj = {}         
          dataobj["Amount"] = parseInt(word.Amount);       
          this.ManualData.push(dataobj);         
           this.buysell_ManualAmt +=parseInt(word.Amount);             
        })
        }
        if (name == "AdvertiesmentsPayments") {
          let bookingsData = res.StatusPaymentInfo;
          bookingsData.filter(word => {
          let dataobj = {}         
          dataobj["Amount"] = parseInt(word.Amount);       
          this.ManualData.push(dataobj);         
           this.advertise_ManualAmt +=parseInt(word.Amount);             
        })
        }
        if (name == "UserHirePayments") {
          let bookingsData = res.StatusPaymentInfo;
          bookingsData.filter(word => {
          let dataobj = {}         
          dataobj["Amount"] = parseInt(word.Amount);       
          this.ManualData.push(dataobj);         
           this.userpay_ManualAmt +=parseInt(word.Amount);             
        })
        }
      }
      else {
        this.paymentDetails = [];
        this.paymentDetails = res.StatusPaymentInfo;
        this.message = "No PaymentData Found.."
        // this.openSnackBar(res.message, "");
      }
    })
  }
  viewDetais(data) {
    this.showOptions = false;
    // console.log("data", data);
    // console.log("click is generated...");
    // console.log(this.Isclicked)
    this.Isclicked = true;
    this.div1 = true;
    if (data.paymentStatus == "Approved" || data.paymentStatus == "Rejected") {
      this.chkstatus = true;
    }
    else {
      this.chkstatus = false;
    }
    this.paymentInfo = data;
  }

  openOptions() {
    this.showOptions = !this.showOptions;
  }
  updatePaymentSt(status) {
    this.paymentSt = status;
    this.Isclicked = false;
    // console.log(this.paymentSt);
    this.FilterChk = true;
    this.ButtnChk = false;
    this.showOptions = false;
    this.getProjectPayments(this.type);
    
  }

  getProjectPayments(name) {
    this.showOptions = false;
    this.type = name;
    this.clearInputMethod2();
    this.selectedstatus = name;
    this.searchByNameForm.patchValue({
      searchText :""
    })
    if (this.FilterChk == false) {
      if (name == "All") {
        this.ShowGraph = true;
      }
      else {
        this.ShowGraph = false;
      }
      this.ProjectPaymts(name);
    }
    else {
      if (this.FilterChk == true && name == "All") {      
        this.ShowGraph = true;
        this.getProjectPaymentsInfo(name);
      }
      if (name != "All" && this.paymentSt != "All")       
      {       
        this.getProjectPaymentsInfo(name);
      }
    }
  }


  getProjectPaymentsInfo(name) {
    this.showOptions = false;
    let p2payload;
    p2payload = {
      type: name,
      PaymentStatus: this.paymentSt
    }
    this.paymentDetails = [];
    // console.log("param", p2payload);
    this.adminService.getPaymentsData(p2payload).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails = res.filterInfo;
      }
      else {
        // console.log("######");
        this.paymentDetails = [];
        this.paymentDetails = res.StatusPaymentInfo;
        this.openSnackBar(res.message, "");
      }
    })
    this.FilterChk = false;
  }

  Approve(payInfo) {
    this.showOptions = false;
    // console.log("payInfo", payInfo);
    this.paymentInfo = payInfo;
    let ApprovePayload =
    {
      userID: payInfo.userID,
      PaymentStatus: "Approved",
      type: payInfo.type,
      postID: payInfo.postID,
      transactionID: payInfo.transactionID,
    }
    // console.log("apppayload:", ApprovePayload);
    this.adminService.PaymnetStatus(ApprovePayload).subscribe((res) => {
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.div1 = false;
        this.ProjectPaymts(this.type);
        //this.router.navigateByUrl('/admin/payments');
        // this.reloadComponent();
        //this.refresh();
      }
      else {
        this.openSnackBar(res.message, "")
      }
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);
      this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }

  refresh(): void {
    window.location.reload();
  }

  reloadComponent() {
    let currentUrl = this.router.url;
    // console.log("url", currentUrl)
    this.router.routeReuseStrategy.shouldReuseRoute = () => true;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }

  Reject(payInfo) {
    this.showOptions = false;
    this.paymentInfo = payInfo;
    let RejectPayload =
    {
      userID: payInfo.userID,
      PaymentStatus: "Rejected",
      type: payInfo.type,
      postID: payInfo.postID,
      transactionID: payInfo.transactionID,
    }
    this.adminService.PaymnetStatus(RejectPayload).subscribe((res) => {
      if (res.response == 3) {
        this.openSnackBar(res.message, "");
        this.div1 = false;
        this.ProjectPaymts(this.type);
      }
      else {
        this.openSnackBar(res.message, "")
      }
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);
      this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }

  getPaymentResult(status) {
    this.showOptions = false;
    let TablePayload = {
      filterType: status
    }
    // console.log(TablePayload);
    this.adminService.PaymentTableData(TablePayload).subscribe((res) => {
      if (res.response == 3) {
        this.PaymentTable = res.info;
        this.count = res.count;
        this.filtertypeAmount = res.filterTypeAmount;
        this.totalAmount_Manual = res.AllTotalManualPayments;
        // console.log(res.info);
      }
      else {
        this.PaymentTable = res.info;
        this.PaymentTable = [];
        this.count = res.count;
        this.filtertypeAmount = res.filterTypeAmount;
        this.totalAmount_Manual = res.AllTotalManualPayments;
        this.openSnackBar(res.message, "")
      }
      this.DateFilter = [];
    }, (err: HttpErrorResponse) => {
      this.adminService.showLoader.next(false);
      this.openSnackBar(err.message, "");
      if (err.error instanceof Error) {
        console.warn("CSError", err.error)
      } else {
        console.warn("SSError", err.error)
      }
    })
  }

  getThisWeek() {
    this.showOptions = false;
    this.isBookingDateRange = false;
    this.bookingsForm.setValue({ dateRange: "" });
    this.status = {
      getTypeFilter: "thisWeek"
    }
    let label = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
    this.lineChartLabels = label;
    this.getPaymentResult("thisWeek");
  }

  getThisMonth() {
    this.showOptions = false;
    this.isBookingDateRange = false;
    this.bookingsForm.setValue({ dateRange: "" });
    this.status = {
      getTypeFilter: "thisMonth"
    }
    let label = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12',
      '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
      '25', '26', '27', '28', '29', '30', '31'];
    this.lineChartLabels = label;
    this.getPaymentResult("thisMonth");
  }

  getThisYear() {
    this.showOptions = false;
    this.isBookingDateRange = false;
    this.bookingsForm.setValue({ dateRange: "" });
    this.status = {
      getTypeFilter: "thisYear"
    }
    let label = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    this.lineChartLabels = label;
    this.getPaymentResult("thisYear");
  }

  getBookingByRange() {
    this.bookingsForm1.setValue({dateRange1: "" });  
    this.searchByNameForm.setValue({searchText: ""})  
    //  this.searchText = "";
    this.showOptions = false;  
    this.isBookingDateRange = true;
    this.DateFilter1 = [];

    let frmDate = new Date(this.bookingsForm.value.dateRange[0]).getTime();
    let toDate = new Date(this.bookingsForm.value.dateRange[1]).getTime();
    frmDate = new Date(this.bookingsForm.value.dateRange[0]).setHours(0, 0, 0, 0);
    toDate = new Date(this.bookingsForm.value.dateRange[1]).setHours(23, 59, 59, 999);

    this.DateFilter.push(frmDate);
    this.DateFilter.push(toDate);
    let p = "[" + frmDate + "," + toDate + "]";
    this.getPaymentResult(p);
  }

  openOptions_online() {
    this.showOptions_online = true;
  }

  Online_ProjectPaymnets(onlinename) {
    // console.log("OnlinePayStatus", onlinename);
    // this.attachmentlistArray = [];
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    this.message = "No PaymentData Found..";
    // console.log("totalonlinepaymnet_projectpay",this.totalonlinepaymnet_projectpay)
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails_online = res.bookingsData;
        if(res.bookingData.attattachments && res.bookingData.attattachments){
          res.bookingData.attattachments.forEach(val=>{
            return this.attachmentlistArray.push(this.baseurl+val);
          })
          // console.log("Attachments",this.attachmentlistArray)
        }       
      }
      else {
        this.paymentDetails_online = [];
        this.paymentDetails_online = res.bookingsData;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }
  Online_ProfileViewPayments(onlinename) {
    // console.log("OnlinePayStatus", onlinename);
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    this.message = "No PaymentData Found..";
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {
      if (res.response == 3) {
        // console.log("res", res.profileviewpaymentData);
        this.paymentDetails_online = res.profileviewpaymentData;
      }
      else {
        this.paymentDetails_online = [];
        this.paymentDetails_online = res.Info;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }
  Online_ProjectTopTenListsPayments(onlinename) {
    // console.log("OnlinePayStatus", onlinename);
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    this.message = "No PaymentData Found..";
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails_online = res.projectsToptenlistData;
      }
      else {
        this.paymentDetails_online = [];
        this.paymentDetails_online = res.projectsToptenlistData;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }
  Online_BuySellProjectsPayments(onlinename) {
    // console.log("OnlinePayStatus", onlinename);
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    this.message = "No PaymentData Found..";
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails_online = res.buyandsellProjectsData;
        // console.log("buysell", this.paymentDetails_online);
      }
      else {
        this.paymentDetails_online = [];
        this.paymentDetails_online = res.Info;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }
  Online_AdvertiesmentsPayments(onlinename) {
    this.isHourly = false;
    // console.log("OnlinePayStatus", onlinename);
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    this.message = "No PaymentData Found.."
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails_online = res.advertiesmentsData;    
        // console.log("adver",res.advertiesmentsData);    
        this.isHourly =res.advertiesmentsData[0].isHourly;
        // console.log("ishourly", res.advertiesmentsData[0].isHourly)
      }
      else {
        this.paymentDetails_online = [];
        this.paymentDetails_online = res.Info;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }

  Online_HireUser(onlinename) {
    this.isHourly = false;
    // console.log("OnlinePayStatus", onlinename);
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    this.message = "No PaymentData Found.."
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {
      if (res.response == 3) {
        this.paymentDetails_online = res.advertiesmentsData;    
        // console.log("hireuser",res.advertiesmentsData);    
        // this.isHourly =res.advertiesmentsData[0].isHourly;
        // // console.log("ishourly", res.advertiesmentsData[0].isHourly)
      }
      else {
        this.paymentDetails_online = [];
        this.paymentDetails_online = res.Info;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }

  getProjectPayments_Online(onlinename) {
    this.onlinetype = onlinename;    
    this.selectedstatus_online = onlinename;
    this.paymentDetails_online = [];
    if (onlinename == "ProjectPayments") {
      this.onlineID = 1;
      this.Online_ProjectPaymnets(onlinename);
    }
    if (onlinename == "ProfileViewPayments") {
      this.onlineID = 2;
      this.Online_ProfileViewPayments(onlinename);
    }
    if (onlinename == "ProjectTopTenListsPayments") {
      this.onlineID = 3;
      this.Online_ProjectTopTenListsPayments(onlinename);
    }
    if (onlinename == "BuySellProjectsPayments") {
      this.onlineID = 4;
      this.Online_BuySellProjectsPayments(onlinename);
    }
    if (onlinename == "AdvertiesmentsPayments") {
      this.onlineID = 5;
      this.Online_AdvertiesmentsPayments(onlinename);
    }
    if (onlinename == "UserHirePayments") {
      this.onlineID = 7;
      this.Online_HireUser(onlinename);
    }
    if (onlinename == "All") {
      this.onlineID = 6;
      this.ProjectPaymts_online(onlinename);
    }

  }
  ProjectPaymts_online(onlinename) {
    this.onlineID = 6;
    this.totalonlinepaymnet = 0;
    this.totalonlinepaymnet_projectpay=0 
    this.totalonlinepaymnet_profilepay = 0;
    this.totalonlinepaymnet_top10pay = 0;
    this.totalonlinepaymnet_buysellpay = 0;
    this.totalonlinepaymnet_advertisepay = 0;
    this.totalonlinepaymnet_hireuser = 0;
    // console.log("Second", onlinename);
    this.Isclicked_online = false;
    this.onlinetype = onlinename;
    let p1Payload = {
      type: onlinename
    }
    this.onlinetype = onlinename;
    //this.message = "No PaymentData Found.."
     this.finalData = [];
     this.attachmentlistArray = [];
    let token = sessionStorage.getItem('token');
    this.adminService.onlinePayments(p1Payload,token).subscribe((res) => {      
      if (res.response == 3) {
        this.totalonlinepaymnet = 0;
        // this.paymentDetails_online = [];
        // this.finalData = [];
        let bookingsData = res.bookingsData;
        bookingsData.filter(word => {
          let dataobj = {}
          dataobj["userProfilePic"] = word.customerProfilePic;
          dataobj["PaymentType"] = "ProjectPayment";
          dataobj["Title"] = word.taskTitle;
          dataobj["userName"] = word.customerName;
          dataobj["userID"] = word.userID;
          dataobj["paymentID"] = word.paymentID;
          dataobj["Amount"] = parseInt(word.taskTotalBudget);
          dataobj["Attachments"] = word.attachments;
          dataobj["SubscriptionPlan"] = "";
          dataobj["TaskDate"] = word.taskDate;
          dataobj["ExpDate"] = word.planExpireDate;
          dataobj["TotalAmount"] = word.taskTotalBudget;
          dataobj["Description"] = word.describeTaskInDetails;
          dataobj["Hours"] = word.hours;
          this.finalData.push(dataobj);
          this.totalonlinepaymnet_projectpay +=parseInt(word.taskTotalBudget);
          this.totalonlinepaymnet +=parseInt(word.taskTotalBudget);

          if(word.attachments && word.attachments){
            word.attachments.forEach(val=>{
              return this.attachmentlistArray.push(this.baseurl+val);
            })
            // console.log("Attachments",this.attachmentlistArray)
          }
        })
        let profileData = res.profileviewpaymentData;
        profileData.filter(word => {
          let dataobj = {}
          dataobj["userProfilePic"] = "";
          dataobj["PaymentType"] = "ProfileViewPayment";
          dataobj["Title"] = "";
          dataobj["userName"] = word.userName;
          dataobj["userID"] = word.userID;
          dataobj["paymentID"] = word.paymentID;
          dataobj["Amount"] = parseInt(word.planPrice);
          dataobj["Attachments"] = "";
          dataobj["SubscriptionPlan"] = word.subscriptionPlan;
          dataobj["TaskDate"] = word.paymentDate;
          dataobj["ExpDate"] = word.planExpireDate;
          dataobj["Description"] = word.describeTaskInDetails;
          dataobj["Hours"] = word.hours;
          this.finalData.push(dataobj);
          this.totalonlinepaymnet_profilepay +=parseInt(word.planPrice);
          this.totalonlinepaymnet +=parseInt(word.planPrice);
        })
        let toptendata = res.projectsToptenlistData;
        toptendata.filter(word => {
          let dataobj = {}
          dataobj["userProfilePic"] = "";
          dataobj["PaymentType"] = "ProjectTop10Payment";
          dataobj["Title"] = "";
          dataobj["userName"] = word.userID;
          dataobj["userID"] = word.userID;
          dataobj["paymentID"] = word.paymentID;
          dataobj["Amount"] = parseInt(word.paymentData.Amount);
          dataobj["Attachments"] = "";
          dataobj["SubscriptionPlan"] = word.subscriptionPlan;
          dataobj["TaskDate"] = word.planStartDate;
          dataobj["ExpDate"] = word.planEndDate;
          dataobj["Description"] = word.describeTaskInDetails;
          dataobj["Hours"] = word.hours;
          this.finalData.push(dataobj);
          this.totalonlinepaymnet_top10pay +=parseInt(word.paymentData.Amount);
          this.totalonlinepaymnet +=parseInt(word.paymentData.Amount);
        })
        let buysell = res.buyandsellProjectsData;
        buysell.filter(word => {
          let dataobj = {}
          dataobj["userProfilePic"] = word.appicon;
          dataobj["PaymentType"] = "BuySellProjectPayment";
          dataobj["Title"] = word.projectName;
          dataobj["userName"] = word.projectName;
          dataobj["userID"] = word.paymentData.userID;
          dataobj["paymentID"] = word.buyingID;
          dataobj["Amount"] = parseInt(word.projectCost);
          dataobj["Attachments"] = "";
          dataobj["SubscriptionPlan"] = "";
          dataobj["TaskDate"] = word.paymentDate;
          dataobj["ExpDate"] = "";
          dataobj["Description"] = word.describeTaskInDetails;
          dataobj["Hours"] = word.hours;
          this.finalData.push(dataobj);
          this.totalonlinepaymnet_buysellpay += parseInt(word.projectCost);
          this.totalonlinepaymnet += parseInt(word.projectCost);
        })
        let advertise = res.advertiesmentsData;
        advertise.filter(word => {
          let dataobj = {}
          dataobj["userProfilePic"] = "";
          dataobj["PaymentType"] = word.paymentPay+"Payment";
          dataobj["Title"] = "";
          dataobj["userName"] = word.userName;
          dataobj["userID"] = word.userID;
          dataobj["paymentID"] = word.paymentID;
          dataobj["Amount"] = parseInt(word.planPrice);
          dataobj["Attachments"] = "";
          dataobj["SubscriptionPlan"] = word.subscriptionPlan;
          dataobj["TaskDate"] = word.paymentDate;
          dataobj["ExpDate"] = word.planExpireDate;
          dataobj["Description"] = word.describeTaskInDetails;
          dataobj["Hours"] = word.hours;
          this.finalData.push(dataobj);
          this.totalonlinepaymnet_advertisepay += parseInt(word.planPrice);
          this.totalonlinepaymnet += parseInt(word.planPrice);
          this.isHourly = word.isHourly;
          // console.log("advertise", this.finalData)
         
        })
        let hireuser = res.userHire;
        hireuser.filter(word => {
          let dataobj = {}
          dataobj["userProfilePic"] = "";
          dataobj["PaymentType"] = "HireUser";
          dataobj["Title"] = "";
          dataobj["userName"] = word.userName;
          dataobj["userID"] = word.userID;
          dataobj["paymentID"] = word.paymentID;
          dataobj["Amount"] = parseInt(word.Amount);
          dataobj["Attachments"] = "";
          dataobj["SubscriptionPlan"] = "";
          dataobj["TaskDate"] = word.paymentDate;
          dataobj["ExpDate"] = word.planExpireDate;
          dataobj["Description"] = word.describeTaskInDetails;
          dataobj["Hours"] = word.hours;
          this.finalData.push(dataobj);
          this.totalonlinepaymnet_hireuser += parseInt(word.Amount);
          this.totalonlinepaymnet += parseInt(word.Amount);
          this.isHourly = word.isHourly;
         
        })
        // console.log("finaldata", this.finalData);
        this.paymentDetails_online = this.finalData;
        // console.log("AllData", this.paymentDetails_online);
        // console.log("totalsum", this.totalonlinepaymnet);
      }
      else {
        this.paymentDetails_online = [];
        this.totalonlinepaymnet = 0.00;
        this.paymentDetails_online = res.Info;
        //this.openSnackBar(res.message, "");
        this.message = "No PaymentData Found.."
      }
    })
  }

  showGallery() {
    let data = {
      isFromGallery: false,
      images: this.attachmentlistArray
    }
    // console.log("attacharray", data);
    let dialogRef = this.dialog.open(ImgPreviewComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: false,
      width: '40rem',
      data: data
    })
  }
  viewDetais_online(data5) {
    // console.log("data", data5);
    // console.log("click is generated...");
    // console.log(this.Isclicked_online)
    this.Isclicked_online = true;
    this.div1_online = true;
    this.paymentInfo_online = data5;
  }
  userDetails(id) {
    // console.log("userid", id);
    this.adminService.showLoader.next(true);
    this.isUserDetails = true;
    this.userID = id;
  }
  receiveMessage(event) {
    this.isUserDetails = event;
  }

























}