import { Component, OnInit,EventEmitter,SimpleChanges,Input, OnChanges, Output, } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ImgPreviewComponent } from '../img-preview/img-preview.component';

@Component({
  selector: 'app-purchase-projects-details',
  templateUrl: './purchase-projects-details.component.html',
  styleUrls: ['./purchase-projects-details.component.css']
})
export class PurchaseProjectsDetailsComponent implements OnInit {
  public baseUrl:string;
  routeSub:any;
  id:string;
  @Input() childID: string;
  @Output() closeEvent = new EventEmitter();
  isFromAllTask:boolean = false;
  rawData:any;
  attachments:Array<any> = [];
  projectID: string = "";
  customerID : string = "";
  isUserDetails : boolean = false;
  constructor(
    private adminService:AdminService,
    private activatedRoute:ActivatedRoute,
    private dialog:MatDialog, 
    private snackBar : MatSnackBar
  ) { }

  ngOnInit() {
    this.onLoad();
  }

  onLoad(){
    this.baseUrl = this.adminService.baseUrl;
    this.routeSub = this.activatedRoute.params.subscribe(params => {
      //log the entire params object
      // console.log(params);
     if(params && params['id']){
      this.id = params['id'];
       this.getProjectDetails()
     }
   }); 
  }
  ngOnChanges(changes: SimpleChanges){
    this.isFromAllTask = true
    this.id = this.childID;
    this.getProjectDetails();
  }
  closeDetails(){
    this.isFromAllTask = false;
    this.closeEvent.emit(true)
  }
  showGallery() {
    let data = {
      isFromGallery: false,
      images: this.attachments
    }
    let dialogRef = this.dialog.open(ImgPreviewComponent, {
      panelClass: 'col-md-4',
      hasBackdrop: true,
      disableClose: false,
      width: '40rem',
      data: data
    })
  }
  getProjectDetails(){
    let obj = {
      projectID: this.id
    }
    let token = sessionStorage.getItem("token");
    this.adminService.showLoader.next(true);
    this.adminService.getMyProjects(obj,token).subscribe((posRes)=>{
    this.adminService.showLoader.next(false);
    // console.log("data test",posRes);
      if(posRes.response == 3){
        this.rawData = posRes.project;
        // console.log("data",this.rawData);
        this.customerID = this.rawData.customerID.substring(0,this.rawData.customerID.lastIndexOf("@"));
        if(this.rawData.projectScreens && this.rawData.projectScreens.length){
          this.rawData.projectScreens.forEach(val=>{
            return this.attachments.push(this.baseUrl+val);
          })
          // console.log("Attachments",this.attachments)
        }
      }else{
        this.openSnackBar(posRes.message,"")
      }
    },(err:HttpErrorResponse)=>{
      this.adminService.showLoader.next(false);
      if(err.error instanceof Error){
        console.warn("Client Error",err.error)
      }else{
        console.warn("Server Error",err.error)
      }
      
    })
  }
  receiveMessage(event) {
    this.isUserDetails = event;
  }
  userDetails(id) {
    this.adminService.showLoader.next(true);
    this.isUserDetails = true;
    this.projectID = id;
  }

  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  
}
