import { Component, OnInit,EventEmitter,SimpleChanges,Input, OnChanges, Output, } from '@angular/core';
import { AdminService } from 'src/app/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HttpErrorResponse } from '@angular/common/http';
import { ImgPreviewComponent } from '../img-preview/img-preview.component';

@Component({
  selector: 'app-purchase-bookings-details',
  templateUrl: './purchase-bookings-details.component.html',
  styleUrls: ['./purchase-bookings-details.component.css']
})
export class PurchaseBookingsDetailsComponent implements OnInit {
  public baseUrl:string;
  routeSub:any;
  id:string;
  @Input() childID: string;
  @Output() closeEvent = new EventEmitter();
  isFromAllTask:boolean = false;
  rawData:any;
  attachments:Array<any> = [];
  buyingID: string = "";
  userid: string = "";
  SellerID : string = "";
  BuyerID : string = "";
  isUserDetails : boolean = false;
  // baseurl : string ="";
  userID : string ="";
  constructor(
    private adminService:AdminService,
    private activatedRoute:ActivatedRoute,
    private dialog:MatDialog, 
    private snackBar : MatSnackBar
  ) { }

  ngOnInit() {    
    this.onLoad();
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000
    });
  }
  onLoad(){
    this.baseUrl = this.adminService.baseUrl;
    this.routeSub = this.activatedRoute.params.subscribe(params => {      
      // console.log("params",params);
     if(params && params['id']){
      this.id = params['id'];
       this.getBookingDetails()
     }
   }); 
  }
  ngOnChanges(changes: SimpleChanges){
    this.isFromAllTask = true;
    this.id = this.childID;
    this.getBookingDetails();
  }
  closeDetails(){
    this.isFromAllTask = false;
    this.closeEvent.emit(true)
  }

  getBookingDetails(){
    // console.log("this.id", this.id);
    let obj = {
    userID: this.id
    }
    let token = sessionStorage.getItem("token");
    this.adminService.showLoader.next(true);
    this.adminService.getMyBookings(obj,token).subscribe((posRes)=>{
    this.adminService.showLoader.next(false);
    // console.log("data test",posRes);
      if(posRes.response == 3){
        this.rawData = posRes.FetchData[0];
        // // console.log("data",this.rawData);
        //  this.SellerID = 
        //  this.rawData.sellerInformation.sellerId.substring(0,this.rawData.sellerInformation.sellerId.lastIndexOf("@"));
        //  this.BuyerID = 
        //  this.rawData.BuyerInfomation.BuyerID.substring(0,this.rawData.BuyerInfomation.BuyerID.lastIndexOf("@"));
       
         // if(this.rawData.projectScreens && this.rawData.projectScreens.length){
        //   this.rawData.projectScreens.forEach(val=>{
        //     return this.attachments.push(this.baseUrl+val);
        //   })
        //   // console.log("Attachments",this.attachments)
        // }
      }else{
        this.openSnackBar(posRes.message,"")
      }
    },(err:HttpErrorResponse)=>{
      this.adminService.showLoader.next(false);
      if(err.error instanceof Error){
        console.warn("Client Error",err.error)
      }else{
        console.warn("Server Error",err.error)
      }
      
    })
  }

  userDetails(id) {
    // console.log("userid",id);
    this.adminService.showLoader.next(true);
    this.isUserDetails = true;
    this.userID = id;
  }

  receiveMessage(event) {
    this.isUserDetails = event;
  }
}
