import { Component,Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatSnackBar } from '@angular/material';
import { AdminService } from '../../admin.service';
import { ReportsComponent } from '../reports/reports.component';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-preffered-lists',
  templateUrl: './preffered-lists.component.html',
  styleUrls: ['./preffered-lists.component.css']
})
export class PrefferedListsComponent implements OnInit {
  selectedList: Array<any> = [];
  isSelf: boolean = false;
  list: Array<any>
  constructor(
    private dialogRef: MatDialogRef<ReportsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private adminService : AdminService
  ) { }

  ngOnInit() {
   // this.list = this.data.list;
   this.getCategories();
  }
  closeTab() {
    this.dialogRef.close();
  }
   //message alerts showing
   openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
      verticalPosition: "top",
    });
  }
  setAll(event, index) {
    if (event) {
      this.selectedList.push({ categoryId: this.list[index].categoryId, categoryName: this.list[index].categoryName });
      // this.selectedList.push(this.list[index].categoryName);
    } else {
      let i = -1;
      i = this.selectedList.findIndex((cat) => {
        //console.log(cat);
        return cat.categoryName === this.list[index].categoryName;
      });
     //  console.log(i);
      if (i != -1) {
        this.selectedList.splice(i, 1);
      }
    }
  }
  submit() {
    if (this.selectedList.length) {
      this.dialogRef.close(this.selectedList);
    } else {
      this.openSnackBar("Select atleast one category", "");
    }
  }

  getCategories() {
    this.adminService.showLoader.next(true);
    this.adminService.browseCategory().subscribe(
      (res) => {
        this.adminService.showLoader.next(false);
        if (res.response == 3) {
         // this.loginService.categoriesList = res;
          this.list = res.categoriesList;
          this.list = this.list.map((cat) => {
            cat.selected = false;
            return cat;
          });
          this.data.selected.forEach((val) => {
            let i = -1;
            i = this.list.findIndex((cat) => {
              return val.categoryName === cat.categoryName;
            });
            if (i != -1) {
              this.list[i].selected = true;
            }
          });
          this.selectedList = [...this.data.selected];
          this.isSelf = false;

        } else {
          this.openSnackBar(res.message, "");
        }
      },
      (err: HttpErrorResponse) => {
        this.adminService.showLoader.next(false);
        this.openSnackBar(err.message, "");

        // console.log("Error", err);
      }
    );
  }


}
