import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute, Router } from "@angular/router";
import { AdminService } from 'src/app/admin.service';

@Component({
  selector: 'app-add-project',
  templateUrl: './add-project.component.html',
  styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
  previewImg: any = "../../../assets/image_1.png";
  previewPPT: string = "";
  projectSourceCodeName: string = "";
  projectScreenName: string = "";
  selectPic: boolean = false;
  appIcon: any = "";
  projectppt: any;
  isPptValid: boolean = true;
  isScreenValid: boolean = true;
  isSourceCodeValid: boolean = true;
  projectsourcecode: any;
  projectscreens: Array<any> = [];
  projectscreensUrls: Array<any> = [];
  addProjectForm: FormGroup;
  selectedCat: string = "";
  user: any;
  categoryList: Array<any> = [];
  isUpdate: boolean = false;
  id: string = "";
  baseUrl: string = "";
  projectData: any = {};
  allUpdated: boolean = true;
  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private snackBar: MatSnackBar,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private http: HttpClient,
    private adminService: AdminService,
  ) { }

  ngOnInit() {
    this.baseUrl = this.adminService.baseUrl;
    this.addProjectForm = this.fb.group({
      appicon: ["", Validators.required],
      projectName: ["", Validators.required],
      userID: ["", Validators.required],
      projectDescription: ["", Validators.required],
      playstoreLink: ["", Validators.required],
      appstoreLink: ["", Validators.required],
      projectVideoLink: ["", Validators.required],
      category: ["", Validators.required],
      projectCost: [
        "",
        [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      ],
      projectExplainCostPerHour: [
        "",
        [Validators.required, Validators.pattern(/^-?(0|[1-9]\d*)?$/)],
      ],
      project_UIUX_XD_Link: ["", Validators.required],
    });
    this.browseCategory();
  }
  selectCat(category) {
    this.addProjectForm.get("category").setValue(category);
    this.selectedCat = category;
  }
  //Image Upload
  fileProgress(event: any) {
    this.selectPic = true;
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        //this.adminProfileForm.get('profilePic').setValue(file);
        this.addProjectForm.get("appicon").setValue(file);
        this.previewImg = reader.result;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
      // Browse Category
 browseCategory(){
  this.adminService.browseCategory().subscribe((posRes)=>{
    if(posRes.response == 3){
      this.categoryList = posRes.categoriesList;
    }else{
      // this.openSnackBar(posRes.message,"")
    }
  },(err:HttpErrorResponse)=>{
    if(err.error instanceof Error){
      console.warn("CSE",err.message);
    }else{
      console.warn("SSE",err.message);
      
    }
  })
}
  // Project Screens
  projectScreensUpload(event: any) {
    this.selectPic = true;
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        this.projectscreensUrls.push(reader.result);
        this.projectscreens.push(file);
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  //Image Upload
  fileProgress1(event: any) {
    this.selectPic = true;
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    this.previewPPT = file.name;
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        //this.adminProfileForm.get('profilePic').setValue(file);
        let ppt = reader.result;
        //// // console.log("ppt ...",ppt);
        this.projectppt = file;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }

  //Image Upload
  fileProgress2(event: any) {
    this.selectPic = true;
    let reader = new FileReader(); // HTML5 FileReader API
    let file = event.target.files[0];
    this.projectSourceCodeName = file.name;
    // // console.log("file name...", file.name);
    if (event.target.files && event.target.files[0]) {
      reader.readAsDataURL(file);
      // When file uploads set it to file formcontrol
      reader.onload = () => {
        //this.adminProfileForm.get('profilePic').setValue(file);
        let ppt = reader.result;
        //// // console.log("ppt ...",ppt);
        this.projectsourcecode = file;
      };
      // ChangeDetectorRef since file is loading outside the zone
      this.cd.markForCheck();
    }
  }
  //message alerts showing
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 3000,
      panelClass: "red-snackbar",
    });
  }
  updateUserProfileMethod() {
    this.allUpdated = false;
    // this.addProjectForm.get("category").setValue(this.selectedCat);
    if (!this.addProjectForm.valid) {
      this.addProjectForm.markAllAsTouched();
      this.openSnackBar("Please Enter all Details", "");
      this.allUpdated = true;
      return;
    }
    if (!this.projectscreens.length) {
      this.openSnackBar("Upload your project screens..", "");
      this.isScreenValid = false;
      this.allUpdated = true;
      return;
    }
    if (!this.projectppt) {
      this.openSnackBar("Upload your project PPT..", "");     
      this.isPptValid = false;
      this.allUpdated = true;
      return;
    }
    if (!this.projectsourcecode) {
      this.openSnackBar("Upload your project source code..", "");
      this.isSourceCodeValid = false;
      this.allUpdated = true;
      return;
    }
    let formData = new FormData();
    let payLoad = { ...this.addProjectForm.value };
    // console.log(payLoad);
    if (this.isUpdate) {
      payLoad.projectID = this.projectData.projectID;
      delete payLoad.userID;
    }
    formData.append("appicon", payLoad.appicon);
    delete payLoad.appicon;
    formData.append("projectInfo", JSON.stringify(payLoad));
    formData.append("projectppt", this.projectppt);
    formData.append("projectsourcecode", this.projectsourcecode);
    this.projectscreens.forEach((val, index) => {
      formData.append(`projectscreens${index}`, val);
    });
    // formData.append("projectscreens", this.projectscreens.toString());
    if (this.isUpdate) {
      this.updateProject(formData);
      return;
    }
    let token = localStorage.getItem("token");
    this.adminService.addProject(formData, token).subscribe(
      (res) => {
        this.openSnackBar(res.message, "");
        if (res.response == 3) {
          this.router.navigateByUrl("/admin/purchaseProjects");
        }
      },
      (err: HttpErrorResponse) => {
        this.openSnackBar(err.message, "");
      }
    );
  }
  updateProject(formData) {
    let token = localStorage.getItem("token");
    this.adminService.updateProject(formData, token).subscribe(
      (res) => {
        this.openSnackBar(res.message, "");
        if (res.response == 3) {
          this.router.navigateByUrl("/admin/purchaseProjects");
        }
      },
      (err: HttpErrorResponse) => {
        this.openSnackBar(err.message, "");
      }
    );
  }
  // Remove selected Screens
  removeImg(index) {
    this.projectscreensUrls.splice(index, 1);
    this.projectscreens.splice(index, 1);
  }
  backToProjects() {
    this.router.navigateByUrl("/admin/purchaseProjects");
  }

}
